import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/config/environments/environment';




export class httpHeaderInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('user');
    if(req.url.startsWith('https://openweathermap.org/data/2.5') || req.url.startsWith('https://api.openweathermap.org/data/2.5') || req.url.startsWith('https://api.openweathermap.org/geo/1.0/direct')){
      req = req.clone({
        headers: new HttpHeaders({
         })
      });
    }
    else if (req.url == `${environment.authApiUrl}/api/v1/refreshToken`) {
      req = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      });
    }
    else if (token) {
      if (req.headers.get('content-Type') === 'multipart/form-data') {
        req = req.clone({
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + token,
          })
        });
      } else {
        if (req.url == `${environment.tpBaseUrl}api/v1/images/uploadFile`) {
          req = req.clone({
            headers: new HttpHeaders({
              'Authorization': 'Bearer ' + token,
            })
          });
        }
        else if (req.url.includes(`${environment.customerApiUrl}api/v1/bulk-upload/staff-validate?zoneId=`) || req.url == `${environment.authApiUrl}/api/v1/bulk-upload/role-validate` || req.url == `${environment.inventoryApiUrl}api/v1/inventory/seat/seat-validate` || req.url == `${environment.inventoryApiUrl}api/v1/inventory/meetingroom/mr-validate` || req.url == `${environment.customerApiUrl}api/v1/bulk-upload/bed-validate`) {
          req = req.clone({
            headers: new HttpHeaders({
              'Authorization': 'bearer ' + token,
            })
          });
        }
        else if (req.url == `${environment.customerApiUrl}api/v1/bulk-upload/validate?industryId=1` || req.url == `${environment.customerApiUrl}api/v1/bulk-upload/validate?industryId=2`) {
          req = req.clone({
            headers: new HttpHeaders({
              'Authorization': 'bearer ' + token,
            })
          });
        }
        else if (req.url == `${environment.authApiUrl}/api/v1/logout` || req.url == `${environment.authApiUrl}/api/v1/refreshToken`) {
          req = req.clone({
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          });
        }
        else {
          req = req.clone({
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'bearer ' + token,
            })
          });
        }
      }
    } else {
      if (localStorage.getItem('resetPassword') == 'true') {
        req = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token,
          })
        });
      } else {
        if (req.url == `${environment.customerApiUrl}api/v1/images/uploadFile`) {
          req = req.clone({
            headers: new HttpHeaders({

            })
          });

        }
        else {
          if (req.url == `${environment.tpBaseUrl}api/v1/images/uploadFile` || req.url == `${environment.tpBaseUrl}api/v1/images/uploadFile`) {
            req = req.clone({
              headers: new HttpHeaders({
              })
            });
          } else {
            req = req.clone({
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
              })
            });
          }
        }
      }
    }

    return next.handle(req);
  }
}

