export const environment = {
  production: true,
  prodConfig: true,
  url: 'https://caleedo.co',
  feedbackFormUrl: 'https://caleedo.co/washroom-feedback/',
  ticketFormUrl: 'https://caleedo.co/washroom-ticket/',
  visitFormUrl: 'https://visit.caleedo.co/access-control/',
  apiBaseUrl: 'https://api-stage.caleedo.net/',
  assetUrl: 'https://api-stage.caleedo.net/asset/',
  cmUrl: 'https://api-stage.caleedo.net/continuousmonitoring/',
  apiUrl: 'http://182.76.226.218:8082/',
  apiUrlV1: 'http://182.76.226.218:8082/api/v1/',
  // rolBaseUrl: 'https://api-stage.caleedo.net/api/v1',
  authApiUrl: 'https://api-stage.caleedo.net/auth',
  newApiUrl: 'https://api-stage.caleedo.net/auth/api/v1/',
  customerApiUrl: 'https://api-stage.caleedo.net/onboarding-service/',
  inventoryApiUrl: 'https://api-stage.caleedo.net/inventory/',
  hygieneApiUrl: 'https://api-stage.caleedo.net/hygiene/',
  patientApiUrl: 'https://api-stage.caleedo.net/patient-service/',
  staffApiUrl: 'https://api-stage.caleedo.net/api/v1/skills',
  notificationApiUrl: 'https://api-stage.caleedo.net/notification-service/',
  tpBaseUrl: 'https://api-stage.caleedo.net/notification-service/',
  visitorUrl: 'https://api-stage.caleedo.net/visitor/api/v1/',
  ravenBaseUrl: 'https://reclean.caleedo.net/',
  firebase: {
    apiKey: "AIzaSyCyzemDYeb6Cx-YKkuQmNDQ9xW4Q95_iEY",
    authDomain: "caleedo-4a17d.firebaseapp.com",
    projectId: "caleedo-4a17d",
    storageBucket: "caleedo-4a17d.appspot.com",
    messagingSenderId: "662973170467",
    appId: "1:662973170467:web:c153194f4004d00714c296",
    measurementId: "G-ZNRQKRF3SY"
  },
  key: "6Lc-p9sfAAAAAAQVk3F47jTjnGj5kmv3GD5bTqYx",
  webinviteUrl: "https://visit.caleedo.co/walk-in-visitor/",
  mapboxAccessToken: 'pk.eyJ1IjoicmFuamFuaXNpbmdoIiwiYSI6ImNsNG50dmo0MzAxNTUzZXF3amQ0MjVnM2IifQ.2nXTf4HTNA6cm6hs20vZQA',
  razorpay: {
    KEYID: 'rzp_test_25FZQqyI5uW525',
    KEYSECRET: 'UY2ri8m3Vj0U4FisXKWUYxkG'
  }
};

// export const environment = {
//   production: true,
//   prodConfig: false,
//   url: 'https://qa.caleedo.net',
//   feedbackFormUrl: 'https://qa.caleedo.net/washroom-feedback/',
//   ticketFormUrl: 'https://qa.caleedo.net/washroom-ticket/',
//   visitFormUrl: 'https://visit.caleedo.net/access-control/',
//   apiBaseUrl: 'https://api-test.caleedo.net/',
//   assetUrl: 'https://api-test.caleedo.net/asset/',
//   cmUrl: 'https://api-test.caleedo.net/continuousmonitoring/',
//   apiUrl: 'http://182.76.226.218:8082/',
//   apiUrlV1: 'http://182.76.226.218:8082/api/v1/',
//   // rolBaseUrl: 'https://api-test.caleedo.xyz/api/v1',
//   authApiUrl: 'https://api-test.caleedo.net/auth',
//   newApiUrl: 'https://api-test.caleedo.net/auth/api/v1/',
//   customerApiUrl: 'https://api-test.caleedo.net/onboarding-service/',
//   inventoryApiUrl: 'https://api-test.caleedo.net/inventory/',
//   hygieneApiUrl: 'https://api-test.caleedo.net/hygiene/',
//   patientApiUrl: 'https://api-test.caleedo.net/patient-service/',
//   staffApiUrl: 'https://api-test.caleedo.net/api/v1/skills',
//   notificationApiUrl: 'https://api-test.caleedo.net/notification-service/',
//   tpBaseUrl: 'https://api-test.caleedo.net/notification-service/',
//   visitorUrl: 'https://api-test.caleedo.net/visitor/api/v1/',
//   ravenBaseUrl: 'https://clean.reaventech.com/',
//   firebase: {
//     apiKey: "AIzaSyCyzemDYeb6Cx-YKkuQmNDQ9xW4Q95_iEY",
//     authDomain: "caleedo-4a17d.firebaseapp.com",
//     projectId: "caleedo-4a17d",
//     storageBucket: "caleedo-4a17d.appspot.com",
//     messagingSenderId: "662973170467",
//     appId: "1:662973170467:web:c153194f4004d00714c296",
//     measurementId: "G-ZNRQKRF3SY"
//   },
//   key: "6Lc-p9sfAAAAAAQVk3F47jTjnGj5kmv3GD5bTqYx",
//   webinviteUrl: "https://visit.caleedo.net/walk-in-visitor/",
//   mapboxAccessToken: 'pk.eyJ1IjoicmFuamFuaXNpbmdoIiwiYSI6ImNsNG50dmo0MzAxNTUzZXF3amQ0MjVnM2IifQ.2nXTf4HTNA6cm6hs20vZQA',
//   razorpay: {
//     KEYID: 'rzp_test_25FZQqyI5uW525',
//     KEYSECRET: 'UY2ri8m3Vj0U4FisXKWUYxkG'
//   }
// };
