import { OverlayModule } from '@angular/cdk/overlay';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatCardModule, MatDividerModule, MatListModule, MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatSnackBar, MatSnackBarModule, MatToolbarModule, MAT_SNACK_BAR_DATA, MatTooltipModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGithub, faMedium, faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare as farCheckSquare, faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faSort, faSortAlphaDown, faSortAlphaUp, faSquare, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { CollapseModule, MDBBootstrapModule, WavesModule } from 'angular-bootstrap-md';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { AuthGuardService } from '../../core/guards/auth-guard.service';
import { ConfirmPasswordComponent } from './component/confirm-password/confirm-password.component';
import { LoaderComponent } from './component/loader/loader.component';
import { MatConfirmDialogComponent } from './component/mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDocumentViewerComponent } from './component/mat-document-viewer/mat-document-viewer.component';
import { PaginationComponent } from './component/pagination-component/pagination.component';
import { ToasterComponent } from './component/toaster/toaster.component';
import { UploadImageComponent } from './component/upload-image/upload-image.component';
import { AbbreviationDirective } from './directives/abbreviation.directive';
import { AlphaNumericDirective } from './directives/alphanumeric.directive';
import { PinCodeDirective } from './directives/pincode.directive';
import { SpecialCharacterDirective } from './directives/special-character.directive';
import { OverlayService } from './services/overlay.service';
import { ToastService } from './services/toaster.service';
import { httpHeaderInterceptor } from '../../../../src/app/core/interceptors/httpHeader.interceptor';
import { DeviceInfoService } from './services/device-detector.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ErrorToasterComponent } from './component/error-toaster/error-toaster.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { MultiFileDropBoxComponent } from './component/multi-file-drop-box/multi-file-drop-box.component';
import { MultiFileDropBoxProgressComponent } from './component/multi-file-drop-box/progress/multi-file-drop-box-progress.component';
import { MultiFileDropBoxDirective } from './directives/multi-file-drop-box.directive';
import { ReadMoreComponent } from './component/read-more/read-more.component';
import { GlobalErrorHandler } from '../../../../src/app/core/global-error-handler';
import { SafeHtmlPipe } from '../../../../src/app/Pipes/domSanitizePipe';
import { MatAddDocumentComponent } from './component/mat-add-document/mat-add-document.component';
import { MatMoveDocumentComponent } from './component/mat-move-document/mat-move-document.component';
import { MatShareDocumentComponent } from './component/mat-share-document/mat-share-document.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { OtpComponent } from './component/otp/otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import {ConfigurationFormFieldComponent} from './component/configuration-form-field/configuration-form-field.component';
import { ListConfigurationFormFieldComponent } from './component/configuration-form-field/components/list-templates/list-configuration-form-field.component';
import { EditConfigurationFormFieldComponent } from './component/configuration-form-field/components/edit-configuration-form-field/edit-configuration-form-field.component';
import { SuccessDialogComponent } from './component/success-dialog/success-dialog';
import { RefreshTokenInterceptor } from '../../../../src/app/core/interceptors/auth-response.interceptor';
import { CarousalService } from './services/carousal.service';
import { SlideAirQualityComponent } from './component/casting/slide-air-quality/slide-air-quality.component';
import { SlideHygieneUpdatesComponent } from './component/casting/slide-hygiene-updates/slide-hygiene-updates.component';
import { SlideWeatherUpdatesComponent } from './component/casting/slide-weather-updates/slide-weather-updates.component';
import { DashboardWeatherUpdatesComponent } from './component/casting/dashboard-weather-updates/dashboard-weather-updates.component';
import { ImageSlideComponent } from './component/casting/image-slide/image-slide.component'
import { SliderHeaderComponent } from './component/casting/slider-header/slider-header.component';
import { SliderFooterComponent } from './component/casting/slider-footer/slider-footer.component';
export { AppOverlayConfig, OverlayService } from './services/overlay.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { QRCodeScannerComponent } from './component/qr-code-scanner/qr-code-scanner.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { SlideAirQualityAmbientWeatherComponent } from './component/casting/slide-air-quality-ambient-weather/slide-air-quality-ambient-weather.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { WebcamModule } from 'ngx-webcam';
import { WebsocketService } from './websocket.service';
import { RatingModule } from 'ngx-rating';
// import { ClipboardModule } from 'ngx-clipboard';

LOAD_WASM().subscribe((res: any) => {});

@NgModule({
  declarations: [
    SpecialCharacterDirective,
    AlphaNumericDirective,
    AbbreviationDirective,
    PinCodeDirective,
    MatConfirmDialogComponent,
    LoaderComponent,
    MatDocumentViewerComponent,
    ConfirmPasswordComponent,
    PaginationComponent,
    UploadImageComponent,
    ToasterComponent,
    ErrorToasterComponent,
    MultiFileDropBoxComponent,
    MultiFileDropBoxProgressComponent,
    MultiFileDropBoxDirective,
    ReadMoreComponent,
    SafeHtmlPipe,
    MatAddDocumentComponent,
    MatMoveDocumentComponent,
    MatShareDocumentComponent,
    ConfirmationDialogComponent,
    OtpComponent,
    ConfigurationFormFieldComponent,
    ListConfigurationFormFieldComponent,
    EditConfigurationFormFieldComponent,
    SuccessDialogComponent,
    SlideAirQualityComponent,
    SlideHygieneUpdatesComponent,
    SlideWeatherUpdatesComponent,
    ImageSlideComponent,
    SliderHeaderComponent,
    SliderFooterComponent,
    DashboardWeatherUpdatesComponent,
    QRCodeScannerComponent,
    SlideAirQualityAmbientWeatherComponent
  ],
  entryComponents: [
    MatConfirmDialogComponent,
    ConfirmPasswordComponent,
    LoaderComponent,
    PaginationComponent,
    UploadImageComponent,
    ConfirmPasswordComponent,
    LoaderComponent,
    MatDocumentViewerComponent,
    ToasterComponent,
    ErrorToasterComponent,
    MatAddDocumentComponent,
    MatMoveDocumentComponent,
    MatShareDocumentComponent,
    ConfirmationDialogComponent,
    OtpComponent,
    SuccessDialogComponent

  ],
  imports: [
    NgxMaterialTimepickerModule,
    ImageCropperModule,
    HttpClientModule,
    // LoaderComponent,
    OverlayModule,
    CommonModule,
    MatGridListModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSortModule,
    MatSlideToggleModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatTabsModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatToolbarModule,
    MatTableModule,
    MatDividerModule,
    MatListModule,
    CdkTreeModule,
    CdkTreeModule,
    MatTreeModule,
    ReactiveFormsModule,
    MatExpansionModule,
    FormsModule,
    CKEditorModule,
    MatStepperModule,
    CdkStepperModule,
    ShowHidePasswordModule,
    CollapseModule.forRoot(),
    WavesModule.forRoot(),
    MatCardModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
    MatSliderModule,
    TranslateModule.forRoot(),
    MDBBootstrapModule,
    NgbModule,
    MatExpansionModule,
    MatInputModule,
    MatSnackBarModule,
    NgxPaginationModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    ToastContainerModule,
    DeviceDetectorModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 15000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgOtpInputModule,
    FontAwesomeModule,
    NgxCaptchaModule,
    NgQrScannerModule,
    NgxScannerQrcodeModule,
    WebcamModule,
    RatingModule
    // ClipboardModule
  ],
  exports: [
    NgxMaterialTimepickerModule,
    FormsModule,
    MatSliderModule,
    MatGridListModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ConfirmPasswordComponent,
    LoaderComponent,
    PaginationComponent,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTabsModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatDividerModule,
    MatListModule,
    CdkTreeModule,
    MatTreeModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatStepperModule,
    CdkStepperModule,
    SpecialCharacterDirective,
    AbbreviationDirective,
    AlphaNumericDirective,
    MatTooltipModule,
    MatConfirmDialogComponent,
    PinCodeDirective,
    ConfirmPasswordComponent,
    UploadImageComponent,
    MatCardModule,
    MatDocumentViewerComponent,
    ShowHidePasswordModule,
    ReactiveFormsModule,
    NgbModule,
    MatExpansionModule,
    MatInputModule,
    MatSnackBarModule,
    NgxPaginationModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    ToastContainerModule,
    MDBBootstrapModule,
    CollapseModule,
    WavesModule,
    TranslateModule,
    DeviceDetectorModule,
    ImageCropperModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    MultiFileDropBoxComponent,
    MultiFileDropBoxProgressComponent,
    MultiFileDropBoxDirective,
    ReadMoreComponent,
    SafeHtmlPipe,
    ConfigurationFormFieldComponent,
    ListConfigurationFormFieldComponent,
    EditConfigurationFormFieldComponent,
    FontAwesomeModule,
    NgxMatSelectSearchModule,
    SlideAirQualityComponent,
    SlideHygieneUpdatesComponent,
    SlideWeatherUpdatesComponent,
    SliderHeaderComponent,
    ImageSlideComponent,
    DashboardWeatherUpdatesComponent,
    NgxCaptchaModule,
    NgQrScannerModule,
    QRCodeScannerComponent,
    SlideAirQualityAmbientWeatherComponent,
    WebcamModule,
    RatingModule
    // ClipboardModule
  ],
  providers: [OverlayService,
    ToastService,
    MatSnackBar,
    CarousalService,
    WebsocketService,
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: httpHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
    DeviceInfoService
  ],

})
export class SharedModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faSquare, faSortAlphaDown, faTimesCircle, faCheckSquare, faSortAlphaUp,
      faSort, farSquare, farCheckSquare, faStackOverflow, faGithub, faMedium)
  }
}
