export const CastingData={
    "slideStayTime" : 15000,
    "slideRefreshTime" : 5000,
    "apiKeyForWeatherUpdate" : '2ae68eb7f9d4b91605f2537f22abc955',
    "unitsForWeatherUpdate" : 'metric',
    "castImageSize" : 25000,
    "apiKeyForWeatherUpdate300":"c296d0a60a47b38b280f1fbe4c231713"
  }

    export const Categories={
      "s100_aqi" : 'S100-AQI',
      "s100_atp" : 'S100-ATP',
      "s200_aqi" : 'S200-AQI',
      "s300_aqi" : 'S300-AQI',
      "s300_atp" : 'S300-ATP',
      "s400_swh" : 'S400-SWH',
      "s400_sws" : 'S400-SWS',
      "s501_swh" : 'S501-SWH',
      "s600_vac" : 'S600-VAC',
    }
    export const CMSFloorMapAccessList={
      "customerIds":''
    }

    export const smartWashroom=[
      {
        title:'Foul Odour',
        titleToShow:'Odour',
        type:'suggestions',
        imgUrl:"assets/images/suggestions/1.png",
        imgUrl2:"assets/images/suggestions/11.png",
        inspectionText:['Exhaust Fan Working','Urinal Cleaned'],
        check:true
      },
      {
        title:'No Toilet Paper',
        titleToShow:'Toilet Paper',
        type:'suggestions',

        imgUrl:"assets/images/suggestions/2.png",
        imgUrl2:"assets/images/suggestions/22.png",
        inspectionText:'Refilled',
        check:false,
        quantityType:'count',
        unit:'m'
      },
      {
        title:'No Hand Soap',
        titleToShow:'Hand Soap',
        type:'suggestions',
        imgUrl:"assets/images/suggestions/3.png",
        imgUrl2:"assets/images/suggestions/33.png",
        inspectionText:'Refilled',
        check:false,
        quantityType:'percentage',
        unitValue:'100,200,250',
        unit:'ml'
      },
      { 
        title:'Litterbin Full',
        titleToShow:'Litterbin',
        type:'suggestions',
        imgUrl:"assets/images/suggestions/4.png",
        imgUrl2:"assets/images/suggestions/44.png",
        inspectionText:'Emptied',
        check:true
      },
      { 
        title:'Sanitary Bin Full',
        titleToShow:'Sanitary Bin',
        type:'suggestions',
        room:'Women Washroom',
        imgUrl:"assets/images/suggestions/5.png",
        imgUrl2:"assets/images/suggestions/55.png",
        inspectionText:'Emptied',
        check:true
      },
      {
        title:'Wet/Dirty Floor',
        titleToShow:'Floor',
        type:'complaints',
        imgUrl:"assets/images/complaints/1.png",
        imgUrl2:"assets/images/complaints/11.png",
        inspectionText:'Cleaned',
        check:true
      },
      {
        title:'Dirty Toilet Bowl',
        titleToShow:'Toilet Bowl',
        type:'complaints',
        imgUrl:"assets/images/complaints/2.png",
        imgUrl2:"assets/images/complaints/22.png",
        inspectionText:'Cleaned',
        check:true
      },
      {
        title:'Dirty Basin',
        titleToShow:'Wash Basin',
        type:'complaints',
        imgUrl:"assets/images/complaints/3.png",
        imgUrl2:"assets/images/complaints/33.png",
        inspectionText:'Cleaned',
        check:true
      },
      {
        title:'Faulty Water Tap',
        titleToShow:'Water Tap',
        type:'complaints',
        imgUrl:"assets/images/complaints/4.png",
        imgUrl2:"assets/images/complaints/44.png",
        inspectionText:'Repaired',
        check:true
      },
      {
        title:'Faulty Light',
        titleToShow:'Light',
        type:'complaints',
        imgUrl:"assets/images/complaints/5.png",
        imgUrl2:"assets/images/complaints/55.png",
        inspectionText:'Replaced',
        check:true
      },
      {
        title:'Faulty Faucet',
        titleToShow:'Faucet',
        type:'complaints',
        imgUrl:"assets/images/complaints/6.png",
        imgUrl2:"assets/images/complaints/66.png",
        inspectionText:'Repaired',
        check:true
      },
      {
        title:'Broken WC Cover',
        titleToShow:'WC Cover',
        type:'complaints',
        imgUrl:"assets/images/complaints/7.png",
        imgUrl2:"assets/images/complaints/77.png",
        inspectionText:'Replaced',
        check:true,
      },
      {
        title:'Faulty Flush Button',
        titleToShow:'Flush Button',
        type:'complaints',
        imgUrl:"assets/images/complaints/8.png",
        imgUrl2:"assets/images/complaints/88.png",
        inspectionText:'Repaired',
        check:true
      },
      {
        title:'WC Door',
        titleToShow:'WC Door',
        type:'complaints',
        imgUrl:"assets/images/complaints/9.png",
        imgUrl2:"assets/images/complaints/99.png",
        inspectionText:'Repaired',
        check:true
      },
      {
        title:'Others',
        titleToShow:'Others',
        type:'suggestions',
        imgUrl:"assets/images/suggestions/6.png",
        imgUrl2:"assets/images/suggestions/66.png",
        inspectionText:['WC Cleaned'],
        check:true
      },
    ]

    export const graphColor=[
      
    ];

    export const SSO={
      clientId:'134b1342-020e-4c80-b538-fec731859b5b',
      ObjectId:'cec37bc8-2268-45ab-a98f-e47e3fea9e3d',
      tenantId:'e286b822-1a67-45ba-b7f6-bc82969822e5'
    }
